<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:navigableDetail="false"
			:externalFilter="externalFilter"
		></pui-datatable>
		<mhscodes-modals :modelName="modelName"></mhscodes-modals>
	</div>
</template>

<script>
export default {
	name: 'mhscodes-grid',
	components: {},
	data() {
		return {
			modelName: 'mhscodes',
			modelColumnDefs: {}
		};
	},
	methods: {
		extendFilter(filter) {
			filter.rules.push({ field: 'level', op: 'eq', data: 4 });
		}
	}
};
</script>
